"use strict";
var _this = this;
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var constants_1 = require("./constants");
var Experiments_1 = require("../common/experiments/Experiments");
var configure_front_bi_logger_1 = require("@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger");
var appToken;
var options;
var biLogger;
var sdk;
var experiments;
function addOrders() {
    var membersAPI = sdk.application.getPublicAPI(appToken, { appDefinitionId: constants_1.membersAppDefId });
    return membersAPI.addSection({
        appDefinitionId: constants_1.ecomAppDefID,
        pageId: constants_1.orderHistoryPageId,
        social: false,
        showInLoginMenu: true,
    });
}
function addCheckout() {
    return sdk.tpa.isAppSectionInstalled(appToken, { sectionId: 'checkout' }).then(function (isInstalled) {
        if (!isInstalled) {
            return sdk.tpa.add.component(appToken, {
                appDefinitionId: constants_1.ecomAppDefID,
                componentType: 'PAGE',
                page: {
                    pageId: 'checkout',
                    shouldNavigate: false,
                },
            });
        }
    });
}
function installMembers() {
    return sdk.application.install(appToken, { appDefinitionId: constants_1.membersAppDefId, initiatorAppDefinitionId: constants_1.ecomAppDefID });
}
function installAddresses() {
    return new Promise(function (resolve, reject) {
        if (experiments.enabled('specs.stores.ExpressCheckout')) {
            sdk.tpa
                .isApplicationInstalled(appToken, { appDefinitionId: constants_1.addressesAppDefId })
                .then(function (isInstalled) {
                if (!isInstalled) {
                    // Temp workaround, till membersAPI will allow to add main section of installed APP.
                    var membersAPI = sdk.application.getPublicAPI(appToken, { appDefinitionId: constants_1.membersAppDefId });
                    return membersAPI.addApplication({
                        appDefinitionId: constants_1.addressesAppDefId,
                        pageId: constants_1.myAddressesPageId,
                        social: false,
                        showInLoginMenu: true,
                    });
                    // return sdk.tpa.add.application(appToken, {appDefinitionId: addressesAppDefId});
                }
            })
                .then(resolve)
                .catch(reject);
        }
        else {
            resolve();
        }
    });
}
function installMyWallet() {
    return new Promise(function (resolve, reject) {
        if (experiments.enabled('specs.stores.ExpressCheckout') && experiments.enabled('specs.cashier.SaveCreditCard')) {
            sdk.tpa
                .isApplicationInstalled(appToken, { appDefinitionId: constants_1.myWalletAppDefId })
                .then(function (isInstalled) {
                if (!isInstalled) {
                    // Temp workaround, till membersAPI will allow to add main section of installed APP.
                    var membersAPI = sdk.application.getPublicAPI(appToken, { appDefinitionId: constants_1.membersAppDefId });
                    return membersAPI.addApplication({
                        appDefinitionId: constants_1.myWalletAppDefId,
                        pageId: constants_1.myWalletPageId,
                        social: false,
                        showInLoginMenu: true,
                    });
                }
            })
                .then(resolve)
                .catch(reject);
        }
        else {
            resolve();
        }
    });
}
function getExperiments(instance) {
    return tslib_1.__awaiter(this, void 0, Promise, function () {
        var experimentsResponse;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch('/_api/wix-ecommerce-storefront-web/api', {
                        method: 'post',
                        body: JSON.stringify({
                            query: require('!raw-loader!../common/experiments/getConfig.graphql'),
                            source: 'WixStoresWebClient',
                            operationName: 'getConfig'
                        }),
                        headers: {
                            'X-ecom-instance': instance,
                            Authorization: instance,
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }).then(function (data) { return data.json(); }).then(function (data) {
                        return (data.data.experiments || []).reduce(function (acc, e) {
                            acc[e.name] = e.value;
                            return acc;
                        }, {});
                    })];
                case 1:
                    experimentsResponse = _a.sent();
                    return [2 /*return*/, new Experiments_1.Experiments(experimentsResponse)];
            }
        });
    });
}
function isMembersInstalled() {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, sdk.tpa.isAppSectionInstalled(appToken, { sectionId: constants_1.orderHistoryPageId })];
        });
    });
}
module.exports = {
    editorReady: function (_editorSDK, _appToken, _options) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var instance, encodedInstance, parsedInstance, storeId, isMerchant;
        var _this = this;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    options = _options;
                    appToken = _appToken;
                    sdk = _editorSDK;
                    return [4 /*yield*/, sdk.document.info.getAppInstance('token')];
                case 1:
                    instance = _a.sent();
                    encodedInstance = instance.substring(instance.indexOf('.') + 1);
                    parsedInstance = JSON.parse(atob(encodedInstance));
                    storeId = parsedInstance.instanceId;
                    isMerchant = true;
                    biLogger = configure_front_bi_logger_1.createStoreFrontBILogger({ uuid: parsedInstance.uid }, parsedInstance.biToken, {
                        storeId: storeId,
                        isMerchant: isMerchant,
                        appName: 'wixstores worker'
                    });
                    return [4 /*yield*/, getExperiments(instance)];
                case 2:
                    experiments = _a.sent();
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var membersInstalled, addressesInstalled, myWalletInstalled;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(options && options.firstInstall)) return [3 /*break*/, 1];
                                        return [2 /*return*/, installMembers().then(resolve, reject)];
                                    case 1: return [4 /*yield*/, isMembersInstalled()];
                                    case 2:
                                        membersInstalled = _a.sent();
                                        if (membersInstalled) {
                                            biLogger.exposureEventForTests({
                                                type: 'members_is_installed',
                                                testName: 'express_checkout',
                                                is_eligible: true
                                            });
                                        }
                                        else {
                                            biLogger.exposureEventForTests({
                                                testName: 'express_checkout',
                                                is_eligible: false
                                            });
                                        }
                                        if (!experiments.enabled('specs.stores.ExpressCheckout')) return [3 /*break*/, 9];
                                        return [4 /*yield*/, sdk.tpa.isApplicationInstalled(appToken, {
                                                appDefinitionId: constants_1.addressesAppDefId
                                            })];
                                    case 3:
                                        addressesInstalled = _a.sent();
                                        return [4 /*yield*/, sdk.tpa.isApplicationInstalled(appToken, { appDefinitionId: constants_1.myWalletAppDefId })];
                                    case 4:
                                        myWalletInstalled = _a.sent();
                                        if (!((!addressesInstalled || !myWalletInstalled) && membersInstalled)) return [3 /*break*/, 9];
                                        if (!experiments.enabled('specs.cashier.SaveCreditCard')) return [3 /*break*/, 6];
                                        return [4 /*yield*/, installMyWallet()];
                                    case 5:
                                        _a.sent();
                                        _a.label = 6;
                                    case 6: return [4 /*yield*/, installAddresses()];
                                    case 7:
                                        _a.sent();
                                        return [4 /*yield*/, sdk.document.save()];
                                    case 8:
                                        _a.sent();
                                        _a.label = 9;
                                    case 9:
                                        resolve();
                                        _a.label = 10;
                                    case 10: return [2 /*return*/];
                                }
                            });
                        }); })];
            }
        });
    }); },
    getAppManifest: function () { return ({}); },
    onEvent: function () {
    },
    handleAction: function (_a) {
        var type = _a.type, payload = _a.payload;
        try {
            switch (type) {
                case 'appInstalled':
                    switch (payload.appDefinitionId) {
                        case constants_1.membersAppDefId: {
                            if (options.firstInstall) {
                                biLogger.exposureEventForTests({ type: 'members_added_by_stores', testName: 'express_checkout', is_eligible: true });
                            }
                            else {
                                biLogger.exposureEventForTests({ type: 'members_added_by_user', testName: 'express_checkout', is_eligible: true });
                            }
                            return Promise.all([addOrders(), addCheckout(), installAddresses(), installMyWallet()]);
                        }
                        default:
                            return Promise.resolve();
                    }
                default:
                    return Promise.resolve();
            }
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    getControllerPresets: function () { return Promise.resolve([]); }
};
